// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-api-h-index-tsx": () => import("./../../../src/pages/api-h/index.tsx" /* webpackChunkName: "component---src-pages-api-h-index-tsx" */),
  "component---src-pages-api-h-job-list-index-tsx": () => import("./../../../src/pages/api-h/JobList/index.tsx" /* webpackChunkName: "component---src-pages-api-h-job-list-index-tsx" */),
  "component---src-pages-api-h-select-jobs-index-tsx": () => import("./../../../src/pages/api-h/SelectJobs/index.tsx" /* webpackChunkName: "component---src-pages-api-h-select-jobs-index-tsx" */),
  "component---src-pages-carreiras-index-tsx": () => import("./../../../src/pages/carreiras/index.tsx" /* webpackChunkName: "component---src-pages-carreiras-index-tsx" */),
  "component---src-pages-carreiras-job-list-index-tsx": () => import("./../../../src/pages/carreiras/JobList/index.tsx" /* webpackChunkName: "component---src-pages-carreiras-job-list-index-tsx" */),
  "component---src-pages-carreiras-select-jobs-index-tsx": () => import("./../../../src/pages/carreiras/SelectJobs/index.tsx" /* webpackChunkName: "component---src-pages-carreiras-select-jobs-index-tsx" */),
  "component---src-pages-go-tech-index-tsx": () => import("./../../../src/pages/go-tech/index.tsx" /* webpackChunkName: "component---src-pages-go-tech-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-product-design-index-tsx": () => import("./../../../src/pages/product-design/index.tsx" /* webpackChunkName: "component---src-pages-product-design-index-tsx" */)
}

